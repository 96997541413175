<div class="container selector-container">
    <div class="selector">
        <h1><b>Hello</b>, please choose your country down bellow</h1>
        <div class="map-wrapper">
            <img src="assets/images/world-gray.svg" alt="">
        </div>

        <div class="select">
            <div class="menu" (click)="isOpen = !isOpen" [ngClass]="{'opened': isOpen}">
                Select country or region
            </div>

            <div class="submenu" *ngIf="isOpen" [@dropDownMenu]>
                <div class="menu-item" *ngFor="let country of countries; let i = index">
                    {{country}}
                </div>
            </div>
        </div>
    </div>
</div>