<div class="nav">
    <div class="container">
        <div class="nav-wrapper">
            <div class="menu">
                <div class="logo-wrapper" [routerLink]="['/home']">
                    <img src="assets/images/clickreview_logo_black.svg" alt="">
                </div>
                <div class="menu-nav">
                    <ul>
                        <li><a [routerLink]="['/home']" routerLinkActive="active-link">{{"Why us" | translate}}</a></li>
                        <li><a [routerLink]="['/how-it-works']" routerLinkActive="active-link">{{"How it Works" | translate}}</a></li>
                        <li><a [routerLink]="['/order']" routerLinkActive="active-link">{{"Order" | translate}}</a></li>
                        <li><a [routerLink]="['/contact']" routerLinkActive="active-link">{{'Contact' | translate}}</a></li>
                        <li class="login"><a href="https://app.clickreview.pro/login" routerLinkActive="active-link">Login</a></li>
                        <li class="languages-dropdown" (click)="openLanguage()">
                            <p>{{selectLang | uppercase}}</p>
                            <img class="main-icon" src="assets/images/sr.svg" alt="" *ngIf="selectLang === 'sr'">
                            <img class="main-icon" src="assets/images/en.svg" alt="" *ngIf="selectLang === 'en'">
                            <img src="assets/images/black-arrow-down.svg" alt="" class="arrow">
                            <ul class="dropdown-ul" *ngIf="language">
                                <li (click)="setTransLanguage('sr')">SR <img src="assets/images/sr.svg" alt=""></li>
                                <li (click)="setTransLanguage('en')">EN <img src="assets/images/en.svg" alt=""></li>
                            </ul>
                        </li>
                    </ul>
                    <div class="cleaner"></div>
                </div>
                <div id="menuToggle">
                    <input type="checkbox" #mobCheck />
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                    <ul id="menu">
                        <li><a [routerLink]="['/home']" (click)="closeMenu()" routerLinkActive="active-link">{{'Why us' | translate}}</a>
                        </li>
                        <li><a [routerLink]="['/how-it-works']" (click)="closeMenu()"
                                routerLinkActive="active-link">{{'How it Works' | translate}}</a></li>
                        <li><a [routerLink]="['/order']" (click)="closeMenu()"
                                routerLinkActive="active-link">{{'Order' | translate}}</a></li>
                        <li><a [routerLink]="['/contact']" (click)="closeMenu()"
                                routerLinkActive="active-link">{{'Contact' | translate}}</a></li>
                        <!-- <li><a [routerLink]="['/news']" (click)="closeMenu()" routerLinkActive="active-link">NEWS</a></li> -->
                        <li><a href="https://app.clickreview.pro/login" class="login"
                                routerLinkActive="active-link">Login</a></li>
                                <li class="languages-dropdown mobile" (click)="openMobLanguage()">
                                    <p>{{selectLang | uppercase}}</p>
                                    <img class="main-icon" src="assets/images/sr.svg" alt="" *ngIf="selectLang === 'sr'">
                                    <img class="main-icon" src="assets/images/en.svg" alt="" *ngIf="selectLang === 'en'">
                                    <img src="assets/images/black-arrow-down.svg" alt="" class="arrow">
                                    <ul class="dropdown-ul" *ngIf="mobLanguage">
                                        <li (click)="setTransLanguage('sr')">SR <img src="assets/images/sr.svg" alt=""></li>
                                        <li (click)="setTransLanguage('en')">EN <img src="assets/images/en.svg" alt=""></li>
                                    </ul>
                                </li>
                    </ul>
                </div>
                <div class="cleaner"></div>
            </div>
        </div>
    </div>
</div>