import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class ScrollService {


    constructor(private router: Router) { }

    setScroll() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
    }
}