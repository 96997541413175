import { Component, OnInit } from '@angular/core';
import { DropDownAnimation } from "../../services/animation";

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  animations: [DropDownAnimation]
})
export class CountrySelectorComponent implements OnInit {
  isOpen = false;

  countries = ['Bosnia & Hercegovina','Cyprus (coming soon)', 'Germany (coming soon)', 'Greece (coming soon)', 'Ireland (coming soon)', 'Italy  (coming soon)', 'Serbia', 'Switzerland', 'United Kingdom (coming soon)']

  constructor() { }

  ngOnInit(): void {
  }

}
