import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  contactForm: FormGroup
  formSuccess: boolean = false;

  constructor(private mailService: MailService) { }

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  send() {
    this.contactForm.markAllAsTouched();
    let formData = 
    {
      "Email": this.f.email.value,
    }

    if (this.contactForm.valid) {
      this.formSuccess = true;
      this.mailService.sendMail(formData).then((data)=>{

      })
    } else {
      console.log("error");
    }
  }

}
