import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild("mobCheck") divView: ElementRef;
  selectLang: string = 'de'
  language: boolean = false
  mobLanguage: boolean = false

  constructor(public translate: TranslateService, private cookieService: CookieService) {
    translate.setDefaultLang('sr');
    translate.addLangs(['en', 'de', 'fr', 'hr', 'sr']);
    translate.use('sr');
   }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(res => {
      this.selectLang = res.lang
    });
  }

  closeMenu(){
    this.divView.nativeElement.checked = false
  }
  
  setTransLanguage(lang) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang)
    this.selectLang = lang
  }

  openLanguage() {
    this.language == false ? this.language = true : this.language = false;
  }

  openMobLanguage() {
    this.mobLanguage == false ? this.mobLanguage = true : this.mobLanguage = false;
  }
}
